import router from "@/router/index.js";

import { GET, POST, DELETE } from "@/use/fetch.js";

// import { jwtDecode } from "@/use/jwt.js";

export const requestsModule = {
    state: () => ({
        data: {
            head: [
                {
                    id: 0,
                    name: "carModel",
                    displayName: "Car/Model",
                },
                {
                    id: 1,
                    name: "plateNumber",
                    displayName: "Plate Number",
                },
                {
                    id: 2,
                    name: "phoneNumber",
                    displayName: "Phone Number",
                },
                {
                    id: 3,
                    name: "typeWork",
                    displayName: "Type Work",
                },
                {
                    id: 4,
                    name: "status",
                    displayName: "Status",
                },
                {
                    id: 5,
                    name: "dateTime",
                    displayName: "Date/Time",
                },
                {
                    id: 6,
                    name: "actions",
                    displayName: "Actions",
                },
            ],
            body: [],
        },

        searchQuery: "",
        status: {
            name: "all",
            displayName: "",
        },
        selectedOptionName: "all",
        sort: {
            fieldName: "id",
            fieldNameCopy: "id",
            ascOrder: true,
        },
        idsItemForDelete: [],
        pagination: {
            current: 1,
            row: localStorage.getItem('paginationRow') ? parseInt(localStorage.getItem('paginationRow')) : 5,
        },

        requestsCount: 0,

        showSideBar: false,
    }),
    getters: {
        dataBodyComputed(state) {
            var dataBody = state.data.body;

            console.log("dataBody setting from state");

            dataBody = dataBody.filter(
                (q) =>
                    q.carModel
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.plateNumber
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.phoneNumber.includes(state.searchQuery.toLowerCase()) ||
                    q.status
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.dateTime.includes(state.searchQuery.toLowerCase())
            );

            if (state.status.name !== "all") {
                dataBody = dataBody.filter(
                    (q) => q.status === state.status.name
                );
            }

            if (state.sort.ascOrder) {
                dataBody.sort((q, w) =>
                    q[`${state.sort.fieldName}`] > w[`${state.sort.fieldName}`]
                        ? 1
                        : w[`${state.sort.fieldName}`] >
                          q[`${state.sort.fieldName}`]
                        ? -1
                        : 0
                );
            } else if (!state.sort.ascOrder) {
                dataBody
                    .sort((q, w) =>
                        q[`${state.sort.fieldName}`] >
                        w[`${state.sort.fieldName}`]
                            ? 1
                            : w[`${state.sort.fieldName}`] >
                              q[`${state.sort.fieldName}`]
                            ? -1
                            : 0
                    )
                    .reverse();
            }

            // if (state.idsItemForDelete !== []) {
            //     var newDataBody = state.data.body;

            //     state.idsItemForDelete.map((q) => {
            //         dataBody = newDataBody.filter((w) => w.id !== q);
            //     });
            // }

            console.log(dataBody);

            dataBody = dataBody.slice(
                (state.pagination.current - 1) * state.pagination.row,
                state.pagination.current * state.pagination.row
            );

            return dataBody;
        },

        dataBodySearchQueryStatus(state) {
            var dataBody = state.data.body;

            dataBody = state.data.body.filter(
                (q) =>
                    q.carModel
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.plateNumber
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.phoneNumber.includes(state.searchQuery.toLowerCase()) ||
                    q.status
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    q.dateTime.includes(state.searchQuery.toLowerCase())
            );

            if (state.status.name !== "all") {
                dataBody = state.data.body.filter(
                    (q) => q.status === state.status.name
                );
            }

            return dataBody;
        },

        numberOfPages(state, getters) {
            if (
                Math.ceil(
                    getters.dataBodySearchQueryStatus.length /
                        state.pagination.row
                ) -
                    1 ===
                0
            ) {
                return 1;
            } else {
                return Math.ceil(
                    getters.dataBodySearchQueryStatus.length /
                        state.pagination.row
                );
            }
        },
    },
    mutations: {
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery;
        },
        customTableSortTable(state, { fieldName, sortAscOrder }) {
            state.sort.fieldName = fieldName;
            state.sort.ascOrder = sortAscOrder;
        },
        customTableOpenItem(state, { itemId }) {
            localStorage.setItem("pitsRequestEdit", false);

            router.push(`/dashboard/${itemId}`);
        },
        customTableOpenItemWithEdit(state, { itemId }) {
            localStorage.setItem("pitsRequestEdit", true);

            router.push(`/dashboard/${itemId}`);
        },
        // customTableDeleteItem(state, {itemId}) {
        //   state.idItemForDelete = itemId;

        //   this.deleteItem(itemId);
        // },
        // customTableSelectAction(state, { actionName, itemId }) {
        //   if (actionName === "edit") {
        //     localStorage.setItem("pitsRequestEdit", true);

        //     router.push(`/request/${itemId}`);
        //   } else if (actionName === "delete") {
        //     state.idItemForDelete = itemId;

        //     // dispatch('deleteItem', { itemId: itemId });
        //   }
        // },
        customSelectUpdate(state, item) {
            state.status.name = item.name;
            state.status.displayName = item.displayName;
        },
        paginationSlidePage(state, { direction }) {
            if (direction === "next") {
                state.pagination.current = state.pagination.current + 1;
            } else if (direction === "previous") {
                state.pagination.current = state.pagination.current - 1;
            }
        },
        paginationSelectPage(state, { page }) {
            state.pagination.current = Number(page);
        },
        openSideBar(state) {
            state.showSideBar = true;
        },
        sidebarSideBar(state) {
            state.showSideBar = false;
        },
        setIdsItemForDelete(state, id) {
            state.idsItemForDelete.push(id);
        },
        setIdItemForDelete(state, id) {
            state.idItemForDelete = id;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
        setRequestsCount(state, requestsCount) {
            state.requestsCount = requestsCount;
        },
        setPaginationRow(state, row) {
            state.pagination.row = parseInt(row)

            localStorage.setItem('paginationRow', row)
        }
    },
    actions: {
        async getData({ commit, state, rootState }) {
            var url = ``;

            if (rootState.permission === "admin") {
                url = `https://theadministration.sermari.online/api/admin/carservice/requests`;
            } else if (rootState.permission === "service") {
                url = `https://theadministration.sermari.online/api/carservice/requests/${rootState.service}`;
                // url = `https://theadministration.sermari.online/api/users/requests`;
            } else if (rootState.permission === 'client') {
                url = `https://theadministration.sermari.online/api/users/requests`;
            }

            const response = await GET(url, rootState.accessToken);

            if (response.status === 200) {
                console.log("data recieved");

                var dataBody = response.data;

                var requestsCount = 0;

                console.log(dataBody)

                state.data.body = dataBody.map((q) => {
                    var typeWork = []

                    for (const w of q.repair_type) {
                        if (w.selected) {
                            var words = String(w.displayName).split(' ')
                            var finalWords = ''

                            for (let i = 0; i < words.length; i++) {
                                finalWords = finalWords + words[i].substring(0, 1).toUpperCase()
                            }

                            typeWork.push(finalWords)
                        }
                    }

                    if (q.status === "new") {
                        requestsCount += 1;
                    }

                    return {
                        id: q.id,
                        carModel: q.car_model,
                        plateNumber: q.car_number,
                        phoneNumber: q.phone,
                        typeWork: typeWork,
                        status: q.status,
                        dateTime: q.updated_at,
                    };
                });

                commit("setRequestsCount", requestsCount);
            } else if (response.status === 401) {
                commit(
                    "setRequestAfterRefreshAccessToken",
                    { module: "requests", name: "getData" },
                    { root: true }
                );
            }
        },

        async customTableDeleteItem(
            { commit, dispatch, rootState },
            { itemId }
        ) {
            const url = `https://theadministration.sermari.online/api/order/${itemId}`;

            const response = await DELETE(url, rootState.accessToken);

            if (response.status === 200 && response.data.status === "ok") {
                commit("setIdsItemForDelete", itemId);
                dispatch("getData");

                // console.log("item deleted");
            }
        },

        async customTableCancelItem({ dispatch, rootState }, { itemId }) {
            const url = `https://theadministration.sermari.online/api/order/update/${itemId}`;
            const json = {
                status: "cancel",
            };

            const response = await POST(url, json, rootState.accessToken);

            if (response.status === 200) {
                dispatch("getData");

                console.log("item canceled");
            }
        },
    },
    namespaced: true,
};
