import "v-calendar/dist/style.css";

import VCalendar from "v-calendar";

import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
// import VueMeta from 'vue-meta'

import store from "./store";

import { languages } from "@/locales";
import { defaultLocale } from "@/locales";
import { createI18n, useI18n } from "vue-i18n";

const localStorageLang = localStorage.getItem("pitsLanguage");

const messages = Object.assign(languages);
const i18n = createI18n({
  legacy: false,
  locale: localStorageLang || defaultLocale,
  fallbackLocale: "en",
  messages,
});

createApp(App, {
  setup() {
    const { t } = useI18n();
    return { t };
  },
})
  .use(VCalendar, {})
  .use(store)
  .use(router)
  .use(i18n)
  .mount("#app");
