export const POST = async (url, json, access, files) => {
  if (files) {
    var options = {};

    var body = new FormData();

    if (json !== {}) {
      body.append("json", JSON.stringify(json));
    }
    body.append("logo", files);

    if (access) {
      options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access}`,
        },
        body: body,
      };
    } else {
      options = {
        method: "POST",
        headers: {},
        body: body,
      };
    }
  } else {
    if (access) {
      options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(json),
      };
    } else {
      options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      };
    }
  }

  const response = await fetch(url, options);

  try {
    const data = await response.json();
    const status = response.status;

    return { data, status };
  } catch {
    const status = response.status;

    return { status };
  }
};

export const GET = async (url, access) => {
  var options = {};

  if (access) {
    options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
  } else {
    options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  const response = await fetch(url, options);

  try {
    const data = await response.json();
    const status = response.status;

    return { data, status };
  } catch {
    const status = response.status;

    return { status };
  }
};

export const DELETE = async (url, access) => {
  var options = {};

  if (access) {
    options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
  } else {
    options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  const response = await fetch(url, options);

  try {
    const data = await response.json();
    const status = response.status;

    return { data, status };
  } catch {
    const status = response.status;

    return { status };
  }
};
