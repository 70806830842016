import { createRouter, createWebHistory } from "vue-router";

import { getCookie } from "@/use/cookie.js";

import LandView from "@/views/land/LandView.vue";

const routes = [
    {
        path: "/",
        name: "land",
        component: LandView,
        redirect: '/clients/auth/sign-in'
    },
    // {
    //     path: "/business/auth/sign-in",
    //     name: "business-sign-in",
    //     component: BusinessSignIn,
    //     beforeEnter: (to, from, next) => {
    //         if (getCookie("pitsAccessToken")) next("/dashboard");
    //         else next();
    //     },
    // },
    {
        path: "/business/auth/sign-in",
        name: "business-sign-in",
        component: () => import("@/views/auth/business/SignInView.vue"),
        beforeEnter: (to, from, next) => {
            console.log(router.app)
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Business Sign In'
        }
    },
    {
        path: "/business/auth/sign-up",
        name: "business-sign-up",
        component: () => import("@/views/auth/business/SignUpView.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Business Sign Up'
        }
    },
    {
        path: "/business/auth/forgot-password",
        name: "business-forgot-password",
        component: () => import("@/views/auth/business/ForgotPasswordView.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Business Forgot Password'
        }
    },
    {
        path: "/clients/auth/sign-in",
        name: "clients-sign-in",
        component: () => import("@/views/auth/clients/SignInView.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Clients Sign In'
        }
    },
    {
        path: "/clients/auth/sign-up",
        name: "clients-sign-up",
        component: () => import("@/views/auth/clients/SignUpView.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Clients Sign Up'
        }
    },
    {
        path: "/clients/auth/forgot-password",
        name: "clients-forgot-password",
        component: () => import("@/views/auth/clients/ForgotPasswordView.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("pitsAccessToken")) next("/dashboard");
            else next();
        },
        meta: {
            title: 'GetPits | Clients Forgot Password'
        }
    },
    {
        path: "/dashboard",
        name: "business-dashboard",
        component: () => import("@/views/main/business/RequestsView.vue"),
        // beforeEnter: (to, from, next) => {
        //     if (!getCookie("pitsAccessToken")) next("/");
        //     else next();
        // },
        beforeEnter: (to, from, next) => {
            if (!getCookie("pitsAccessToken")) next("/clients/auth/sign-in");
            else next();
        },
        meta: {
            title: 'GetPits | Requests'
        }
    },
    {
        path: "/dashboard/:id",
        name: "business-dashboard-id",
        component: () => import("@/views/main/business/RequestView.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("pitsAccessToken")) next("/clients/auth/sign-in");
            else next();
        },
        meta: {
            title: 'GetPits | Request'
        }
    },
    {
        path: "/dashboard/add",
        name: "business-dashboard-add",
        component: () => import("@/views/main/business/RequestAddView.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("pitsAccessToken")) next("/clients/auth/sign-in");
            else next();
        },
        meta: {
            title: 'GetPits | Business Sign In'
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/main/SettingsView.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("pitsAccessToken")) next("/clients/auth/sign-in");
            else next();
        },
        meta: {
            title: 'GetPits | Settings'
        }
    },
    {
        path: "/settings/change-password",
        name: "change-password",
        component: () => import("@/views/main/ChangePasswordView.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("pitsAccessToken")) next("/clients/auth/sign-in");
            else next();
        },
        meta: {
            title: 'GetPits | Change Password'
        }
    },
    // {
    //   path: "/clients",
    //   name: "clients",
    //   component: () => import("@/views/main/clients/ClientsView.vue"),
    //   beforeEnter: (to, from, next) => {
    //     if (!getCookie("pitsAccessToken")) next("/");
    //     else next();
    //   },
    // },
    // {
    //   path: "/client/:id",
    //   name: "client",
    //   component: () => import("@/views/main/clients/ClientView.vue"),
    //   beforeEnter: (to, from, next) => {
    //     if (!getCookie("pitsAccessToken")) next("/");
    //     else next();
    //   },
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`
    next()
})

export default router;
