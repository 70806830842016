<template>
    <router-view />
</template>

<script>
// import axios from "axios";

export default {
    beforeCreate() {
        this.$store.commit("initializeStore");
        this.$store.commit("initializePermission");
        this.$store.commit("initializeId");
        this.$store.commit("initializeService");

        // const access = this.$store.state.access;

        // if (access) {
        //   axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        // } else {
        //   axios.defaults.headers.common["Authorization"] = ``;
        // }
    },
    setup() {
        //
    },
};
</script>

<style lang="sass">
@import @/assets/stylesheets/config
@import @/assets/stylesheets/general
</style>
