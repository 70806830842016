import router from "@/router/index.js";

import { createStore } from "vuex";

import { requestsModule } from "./requestsModule";

import { GET } from "@/use/fetch.js";

import { getCookie, deleteCookie } from "@/use/cookie.js";

import { jwtDecode } from "@/use/jwt.js";

export default createStore({
    state: {
        accessToken: "",
        permissions: {},
        id: 0,
        permission: "",
        service: 0,

        requestAfterRefreshAccessToken: {
            module: "",
            name: "",
        },
    },
    getters: {},
    mutations: {
        initializeStore(state) {
            if (getCookie("pitsAccessToken")) {
                state.accessToken = getCookie("pitsAccessToken");
                state.permissions = jwtDecode(
                    getCookie("pitsAccessToken")
                ).permissions;

                console.log(state.permissions.client);
            } else {
                state.accessToken = "";
                state.permissions = "";
            }
        },
        initializePermission(state) {
            if (state.permissions.admin === 1) {
                state.permission = "admin";
            } else if (state.permissions.service === 1) {
                state.permission = "service";
            } else if (state.permissions.client === 1) {
                state.permission = "client";
            } else {
                state.permission = "";
            }
        },
        initializeId(state) {
            if (state.accessToken) {
                state.id = jwtDecode(state.accessToken).sub
            }
        },
        initializeService(state) {
            if (state.accessToken) {
                state.service = jwtDecode(state.accessToken).services
            }
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setPermission(state, permission) {
            state.permission = permission;
        },
        setRequestAfterRefreshAccessToken(state, obj) {
            state.requestAfterRefreshAccessToken = obj;
        },
    },
    actions: {
        async refreshAccessToken({ state, commit }) {
            const response = await GET(
                `https://theadministration.sermari.online/api/auth/refresh`,
                state.accessToken
            );

            if (response.status === 200) {
                commit("setAccessToken", response.data.authorisation.token);

                for (const request of state.requestsAfterRefreshAccessToken) {
                    console.log(request);
                }
            } else {
                deleteCookie("pitsAccessToken");

                router.push("/business/auth/sign-in");
            }
        },
    },
    modules: {
        requests: requestsModule,
    },
});
