export const getCookie = (name) => {
  var cookie = {};

  document.cookie.split(";").forEach((i) => {
    let [key, value] = i.split("=");

    cookie[key.trim()] = value;
  });

  return cookie[name];
};

export const setCookie = (name, value, expires, secure) => {
  document.cookie = `${name}=${value};${
    !isNaN(expires) ? ` expires=${new Date(expires * 1000)};` : ``
  }${secure ? ` Secure;` : ``} path=/`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export function createCookie(name, value, days) {
  var expires;
  if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = "; expires="+date.toGMTString();
  }
  else {
      expires = "";
  }
  document.cookie = name+"="+value+expires+"; path=/";
}

export function eraseCookie(name) {
  createCookie(name,"",-1);
}
